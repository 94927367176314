<template>
    <div class="app-container" ref='sendRecord' @scroll="scroll">
            <el-page-header @back="goBack" style="margin-bottom: 10px;" class="header">
            <template slot="content">
                批次名称：{{this.$store.state.batch.batch}}
            </template>
        </el-page-header>
        <el-divider style="margin:0px"></el-divider>
        <el-form :inline="true" class="demo-form-inline">
            <!-- <el-form-item label="单位:">
                        <el-select v-model="search.institutionId" size="medium" clearable @change="handleInstitution"
                            placeholder="请选择单位" class="form-line-item">
                            <el-option v-for="(item,index) in institutionList" :key='index'
                                :label="item.institutionName" :value="item.institutionId"></el-option>
                        </el-select>
                    </el-form-item> -->
            <!-- <el-form-item label="批次:">
                        <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                            class="form-line-item" :disabled='batchShow'>
                            <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                :value="item.batchId"></el-option>
                        </el-select>
                    </el-form-item> -->
            <el-form-item label="状态:">
                <el-select v-model="search.statue" size="medium" clearable placeholder="请选择状态" class="form-line-item">
                    <!-- <el-option label="未上传" :value="2"></el-option>
                    <el-option label="已上传" :value="0"></el-option>
                    <el-option label="待审核" :value="1"></el-option>
                    <el-option label="已审核" :value="2"></el-option> -->
                    <el-option label="已提交" :value="0"></el-option>
                    <el-option label="未提交" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="论文题目:">
                <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                    class="form-line-item">
                </el-input>
            </el-form-item>
        </el-form>
        <el-form :inline="true" class="demo-form-inline">
            <!-- <el-form-item label="编号:">
                <el-input placeholder="请输入论文编号" v-model="search.oldId" size='medium' class="form-line-item">
                </el-input>
            </el-form-item> -->
            <el-form-item label="作者:">
                <el-input placeholder="请输入作者" v-model="search.student" size='medium' class="form-line-item">
                </el-input>
            </el-form-item>
            <el-form-item style="margin-left:10px;">
                <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
                    搜索
                </el-button>
                <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
                    重置
                </el-button>
            </el-form-item>
        </el-form>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="small" icon="el-icon-upload2" @click="uploadPaperHandle">上传论文
                    </el-button>
                    <!-- <el-button type="success" size="small" @click="uploadStudentHandle">上传学生名单</el-button> -->
                    <!-- <el-button type="success" size="small">发送上传通知</el-button> -->
                    <el-button type="success" size="small" icon="el-icon-s-promotion" @click="submitSendingHandle">提交送审
                    </el-button>
                    <!-- <el-button type="success" size="small" icon="el-icon-magic-stick" style="float:right">敏感词检测</el-button> -->
                  <el-button type="success" size="small" icon="el-icon-upload2" @click="uploadPaperAnnexHandle">上传附件
                  </el-button>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar paperList" round align="left"
                ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :data="paperList">
                <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="title" title="题目" min-width="300" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="type" title="学位类型" width="250" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="student" title="作者" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="supervisor" title="导师" width="100" :show-overflow="'tooltip'"></vxe-column>
                <!-- <vxe-column field="endTime" title="截至时间" width="150" :show-overflow="'tooltip'"></vxe-column> -->
                <vxe-column title="状态" width="100">
                    <template #default="{ row }">
                        {{$store.state.mapper.paperStateMap[row.state]}}
                    </template>
                </vxe-column>
                <vxe-column title="论文附件" width="200" :show-overflow="'tooltip'">
                  <template #default="{ row }">
                    <div v-if="row.annexUrl">
                      <el-link type="primary" @click="downloadAnnex(row)">{{row.annexUrl}}</el-link>
                    </div>
                    <div v-else>无</div>
                  </template>
                </vxe-column>
                <!-- <vxe-column title="操作" width="120">
                    <template #default="{ row }">
                        <el-button size="mini" type="text" @click="updatePaperHandle(row.paperId)">修改信息</el-button>
                    </template>
                </vxe-column> -->
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes" :layouts="[ 'Total']" @page-change="handlePageChange">
                <!-- <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template> -->
            </vxe-pager>
        </div>

        <el-dialog :title="sendProcessTitle" :show-close='showClose' :close-on-click-modal='false'
            :close-on-press-escape='false' :visible.sync="sendProcessVis" center width="30%">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="process" status="success"></el-progress>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
        </el-dialog>
        <!-- 上传论文 -->
        <vxe-modal v-model="uploadPaperModel" :position="{top: '0px'}" width="800" @close="closeHandle"
            :show-footer='true' show-zoom resize :transfer='true'>
            <template #title>
                上传论文
            </template>
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-upload class="upload-demo" ref='paperInfo' :auto-upload='false' drag action="#">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将论文信息表拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件。</div>
                    </el-upload>
                    <el-link type="primary" href="/files/信息表.xlsx">下载信息表</el-link>
                </el-col>
                <el-col :span="12">
                    <el-upload class="upload-demo" ref='paperRaw' :data="uploadData" :auto-upload='false'
                        action="/api/paper/uploadPaper" :on-success="uploadSuccess" :on-error="uploadError"
                        :on-progress="uploadProgress" drag>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将论文原件压缩包拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传zip文件。</div>
                    </el-upload>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmUploadPaperHandle'>确认</el-button>
            </template>
        </vxe-modal>
      <!-- 上传附件 -->
      <vxe-modal v-model="uploadPaperAnnexModel" :position="{top: '0px'}" width="400" @close="closeAnnexHandle"
                 :show-footer='true' show-zoom resize :transfer='true'>
        <template #title>
          上传附件
        </template>
        <el-row :gutter="1">
          <el-col :span="24">
            <el-upload class="upload-demo" ref='paperAnnex' :auto-upload='false' action="/api/paper/uploadPaperAnnex" multiple drag
                       headers="ContentType:application/json"
                       :data="uploadAnnexData"
                       :file-list="annexFileList"
                       :beforeUpload="beforeAnnexUpload"
                       :on-change="handleAnnexChanged"
                       :on-success="uploadAnnexSuccess"
                       :on-error="uploadAnnexError"
                       :on-progress="uploadAnnexProgress" >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将附件压缩包拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">1、只能上传zip文件。</div>
              <div class="el-upload__tip" slot="tip">2、与论文编号不一致将导致上传失败。</div>
              <div class="el-upload__tip" slot="tip">3、重复上传会覆盖源文件，请及时保存。</div>
              <div class="el-upload__tip" slot="tip">4、请在上传论文原件之后再上传附件，否则将导致上传失败。</div>
            </el-upload>
          </el-col>
        </el-row>
        <template #footer>
          <el-button type="primary" size="small" @click='cfmUploadAnnexHandle'>确认</el-button>
        </template>
      </vxe-modal>

        <!-- 上传学生名单 -->
        <vxe-modal v-model="uploadStudentModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
            resize :transfer='true'>
            <template #title>
                上传学生名单
            </template>
            <el-upload class="upload-demo" ref='uploadStudent' action="/api/#" :auto-upload='false'
                :before-upload="stuBeforeUpload" :on-progress="stuProgress" :on-success="stuSuccess" drag>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将论文信息表拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件，且不超过500kb</div>
            </el-upload>

            <template #footer>
                <el-button type="primary" size="small" @click='cfmUploadStudentHandle'>确认</el-button>
            </template>
        </vxe-modal>
        <!-- 修改论文信息 -->
        <vxe-modal v-model="updatePaperModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom resize
            :transfer='true'>
            <template #title>
                修改论文信息
            </template>
            <vxe-form :data="paperInfo" title-align="right" title-width="100">
                <vxe-form-item field="title" title="论文题目" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.title" placeholder="请输入" size="medium" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="degreeType" title="学位类型" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-select v-model="data.degreeType" placeholder="请选择" size="medium" clearable>
                            <vxe-option value="专业硕士" label="专业硕士"></vxe-option>
                            <vxe-option value="学术硕士" label="学术硕士"></vxe-option>
                        </vxe-select>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="subjectMajor" title="二级学科" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <el-cascader v-model="data.subjectMajor" :options="subjectList" size="small" style="width:100%"
                            :props="{ expandTrigger: 'hover',label:'firstMajor',value:'subjectId'}" clearable
                            filterable>
                        </el-cascader>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="direction" title="研究方向" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.direction" placeholder="请输入" size="medium" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="keywords" title="关键词" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.keywords" placeholder="请输入" size="medium" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="supervisor" title="导师" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <vxe-input v-model="data.supervisor" placeholder="请输入" size="medium" type="text">
                        </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="tutor" title="更改论文原件" :span="24" :item-render="{}">
                    <template #default="{ data }">
                        <el-upload class="upload-demo" drag action="#" :file-list="data.url" :auto-upload='false'>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将论文原件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传pdf文件，且不超过500kb</div>
                        </el-upload>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <el-button type="primary" size="small" @click='cfmUpdatePaperHandle'>确认</el-button>
            </template>
        </vxe-modal>
        <el-dialog :title="uploadTitle" :visible.sync="uploadDiaVis" width="30%" :close-on-click-modal="false"
            :show-close='showClose' :center='true' class="dialog">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="percent" :status="proStatus"></el-progress>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button type="primary" size="small" @click="lookCheckResult" v-if='checkSuccess'>查看检测结果</el-button> -->
                <el-button type="primary" icon="el-icon-download" size="medium" plain @click="downloadError"
                    v-show='!checkSuccess&&showClose'>
                    下载错误信息表</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    // import dateFormat from '../../../public/tools/date'
    import XLSX from 'xlsx'
    export default {
        name: 'PaperLibrary',
        data() {
            return {
                updatePaperModel: false,
                uploadStudentModel: false,
                uploadPaperModel: false,
                //数据加载
                loading: true,
                //输入条件
                input3: '',
                select: '1',
                //折叠面板显示与
                collapseShow: false,
                //搜索条件
                institutionList: [],
                batchList: [],
                singleSearchLabel: 'title',
                singleSearchValue: '',
                student: '',
                search: {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    student: '',
                    statue: '',
                },
                batchShow: true,
                //论文列表
                paperList: [],
                paperInfo: {
                    title: '',
                    degree: '学位类型',
                    subMajor: '二级学科',
                    theme: '研究方向',
                    keywords: '关键词',
                    tutor: '导师',
                    pdf: ''
                },
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [10, 20, 50, 100, 200],
                    total: 0
                },
                //对话框
                receiptDiaVis: false,
                //评阅书列表
                receiptList: [{
                    expertName: 'sh',
                    university: '正高',
                    state: '0',
                    resultKeywords: '',
                    paperKeywords: ''
                }],
                paperKeywords: '',
                //发送进度条
                sendProcessVis: false,
                showClose: false,
                sendProcessTitle: '发送中',
                process: 0,
                sendTimer: '',
                batchId: '',
                //学科列表
                subjectList: [],
                // 上传信息
                uploadData: {},
                percent: 0,
                uploadTitle: '',
                uploadDiaVis: false,
                proStatus: '',
                checkSuccess: false,

                uploadPaperAnnexModel:false,
                annexFileList:[],
                uploadAnnexData: {},
            }
        },
        mounted() {},
        // activated() {
        //     this.$nextTick(() => {
        //         console.log(this.$store.state.sendRecord.scrollTop);
        //         this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
        //     })
        // },
        created() {
            this.batchId = this.$route.params.id;
            this.getOriginalPaperPreviews();
            this.getAllSubject();

            //获取论文列表
            // this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
        },
        methods: {
            updatePaperHandle(paperId) {
                this.getPaperBasicInfo(paperId);
                this.updatePaperModel = true;
            },
            cfmUpdatePaperHandle() {
                console.log(this.paperInfo);
            },

            submitSendHandle() {},
            // 上传学生名单操作
            uploadStudentHandle() {
                this.uploadStudentModel = true;
            },
            cfmUploadStudentHandle() {
                if (this.$refs.uploadStudent.uploadFiles.length < 1) {
                    this.$message.warning("请选择学生名单");
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let rawFile = this.$refs.uploadStudent.uploadFiles[0];
                console.log(rawFile);
                const reader = new FileReader()
                reader.readAsArrayBuffer(rawFile.raw)
                reader.onload = e => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, {
                        type: 'array'
                    });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const tabels = XLSX.utils.sheet_to_json(worksheet);
                    let title = ['学号', '姓名', '导师'];
                    let students = [];
                    for (let i in tabels) {
                        let raw = tabels[i];
                        for (let j in title) {
                            if (typeof (raw[title[j]]) == 'undefined') {
                                this.$message.warning(title[j] + '信息非空');
                            }
                        }
                        let stu = {
                            studentName: raw[title[0]],
                            studentNumber: raw[title[1]],
                            supervisor: raw[title[2]],
                            studentMobile: '1',
                            studentMail: '1',
                        }
                        students.push(stu);
                    }
                    let params = {
                        batchId: this.batchId,
                        uploadStudentBo_studentInfos: students
                    }
                    loading.close();
                    this.$api.paperLibrary.postUploadStudent(params)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("上传成功！");
                                this.getOriginalPaperPreviews()
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护！");
                        })
                };
            },
            stuBeforeUpload(file) {
                return false;
            },
            stuProgress(e, file, fileList) {},
            stuSuccess(res, file, fileList) {},
            closeHandle() {
                console.log(123);   
                this.$refs.paperInfo.uploadFiles.length = 0
                this.$refs.paperRaw.uploadFiles.length = 0;
            },
            // 上传论文
            uploadPaperHandle() {

                this.uploadPaperModel = true;
            },
            cfmUploadPaperHandle() {
                if (this.$refs.paperInfo.uploadFiles.length < 1) {
                    this.$message.warning("请选择论文信息");
                    return;
                }
                if (this.$refs.paperRaw.uploadFiles.length < 1) {
                    this.$message.warning("请选择论文原件");
                    return;
                }
                let excelTitle = [
                    '论文编号（必填，与文件名保持一致）', '论文关键词（必填，中文分号隔开）'
                ];
                //'论文编号', '论文题目', '一级学科', '论文关键词', '摘要', '学校', '导师', '学位类型', '学生'
                var xlsFile = this.$refs.paperInfo.uploadFiles[0];
                var zipFile = this.$refs.paperRaw.uploadFiles[0];
                console.log(zipFile.raw)
                // const loading = this.$loading({
                //     lock: true,
                //     text: '正在上传',
                //     spinner: 'el-icon-loading',
                //     background: 'rgba(0, 0, 0, 0.7)'
                // });
                var reader = new FileReader(); //读取文件内容
                reader.readAsArrayBuffer(xlsFile.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
                reader.onload = (e) => {
                    console.log(this.batchId);
                    var dd = e.target.result;
                    var workbook = XLSX.read(dd, {
                        type: 'buffer'
                    });
                    var sheetname = workbook.SheetNames[0]
                    var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
                    var paperDetails = [];
                    for (let i in tables) {
                        let row = tables[i];
                        for (let i in excelTitle) {
                            if (typeof (row[excelTitle[i]]) == 'undefined') {
                                this.$message.warning(excelTitle[i] + '不可为空');
                                return;
                            }
                        }
                        console.log(row['学位类型（必填）']);

                        // if (row['学位类型（必填）'] != this.auditInfo.paperType) {
                        //     this.$message.warning("上传的论文信息表与所选批次中送审论文类型不一致");
                        //     return;
                        // }
                        var paps = {
                            "oldId": row['论文编号（必填，与文件名保持一致）'],
                            "institution": typeof (row["学校（必填）"]) == 'undefined' ? '' : row[
                                "学校（必填）"],
                            "title": typeof (row["论文题目（必填）"]) == 'undefined' ? '' : row[
                                '论文题目（必填）'],
                            "paperType": typeof (row["学位类型（必填）"]) == 'undefined' ? '' : row[
                                "学位类型（必填）"],
                            "subMajorCode": typeof (row["二级学科代码（必填）"]) == 'undefined' ? '' : row[
                                "二级学科代码（必填）"],
                            "subMajor": typeof (row["二级学科名称（必填）"]) == 'undefined' ? '' : row[
                                "二级学科名称（必填）"],
                            "direction": typeof (row['研究方向']) == 'undefined' ? '' : row['研究方向'],
                            "keywords": typeof (row["论文关键词（必填，中文分号隔开）"]) == 'undefined' ? '' : row[
                                "论文关键词（必填，中文分号隔开）"],
                            "studentNumber": typeof (row['学生学号']) == 'undefined' ? '' : row[
                                '学生学号'],
                            "student": typeof (row["学生姓名（必填）"]) == 'undefined' ? '' : row[
                                "学生姓名（必填）"],

                            "tutor": typeof (row["导师姓名"]) == 'undefined' ? '' : row["导师姓名"],
                            "abstracts": typeof (row["摘要"]) == 'undefined' ? '' : row["摘要"],
                        };
                        paperDetails.push(paps);
                    };
                    // this.uploadData.institutionId = this.institutionId;
                    this.uploadData.batchId = this.batchId;
                    this.uploadData.paperDetails = JSON.stringify(paperDetails);
                    this.$refs.paperRaw.submit();
                    this.percent = 0;
                    this.showClose = false;
                    this.uploadTitle = '正在上传';
                    this.proStatus = '';
                    this.checkSuccess = false;
                    this.uploadDiaVis = true;
                }
            },

            uploadProgress(event, file, fileList) {
                this.percent = parseInt(event.percent);
            },
            uploadError(err, file, fileList) {
                this.$message.warning("服务器维护！");
                this.showClose = true;
                this.percent = 99;
                this.uploadTitle = '上传失败';
                this.proStatus = 'exception';
                this.$refs.paperRaw.clearFiles();
                this.$refs.paperInfo.clearFiles();
            },
            // 下载zip
            getTable(path) {
                this.$api.file.getTables({
                    path: path
                }).then(res => {
                    console.log(res);
                    let data = res.data;
                    let str = res.headers['content-disposition'].split(';')[1].split('=')[1];
                    let filename = '上传报错文件.xls';
                    for (let i = 1; i < str.length - 1; i++) filename += str[i];
                    let blob = new Blob([data], {
                        type: 'application/octet-stream;charset=utf-8'
                    });
                    let url = window.url || window.webkitURL;
                    let href = url.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = href;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(href);
                }).catch(err => {
                    this.$message.warning("导出格式有误");
                })
            },
            uploadSuccess(res, file, fileList) {
                this.showClose = true;
                if (res.code == '200') {
                    this.$message.success("上传成功");
                    this.uploadTitle = '上传成功';
                    this.proStatus = 'success';
                    this.getOriginalPaperPreviews();
                } else {
                    this.uploadTitle = '上传失败';
                    this.errorUrl = res.data;
                    this.proStatus = 'exception';
                    this.percent = 99;
                }
                this.$refs.paperRaw.clearFiles();
                this.$refs.paperInfo.clearFiles();
            },
            // 提交送审
            submitSendingHandle() {
                let paperIds = [];
                this.$refs.paperTable.getCheckboxRecords().forEach(item => {
                    paperIds.push(item.paperId);
                })
                let params = {
                    paperIds: paperIds.join(',')
                }
                this.$api.paperLibrary.getSubmit(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("提交成功！");
                            this.getOriginalPaperPreviews();
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            downloadError() {
                // console.log(this.errorUrl.substring(this.errorUrl.lastIndexOf('\/')));
                this.getTable("/uploadTemp" + this.errorUrl.substring(this.errorUrl.lastIndexOf('\/')));
            },
            goBack() {
                this.$router.push({
                    name: 'batchManage'
                })
            },
            //获取层级学科
            getAllSubject() {
                this.$api.paperLibrary.getAllSubject()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.subjectList = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            //获取单篇论文基本信息
            getPaperBasicInfo(paperId) {
                let params = {
                    paperId: paperId
                }
                this.$api.paperLibrary.getPaperBasicInfo(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.paperInfo = res.data.data;
                            this.paperInfo.url = [{
                                name: '12313312.pdf',
                                url: '12313312.pdf'
                            }]
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            // 获取原文库论文
            getOriginalPaperPreviews() {
                let params = {
                    batchId: this.batchId,
                    filterTitle: '',
                    filterStudent: ''
                }
                this.$api.paperLibrary.getOriginalPaperPreviews(params)
                    .then(res => {
                        if (res.data.code == 200) {
                          console.log(res.data.data)
                            this.paperList = res.data.data;
                            this.page.total = res.data.data.length;
                            this.loading = false;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            //表格刷新
            refresh() {
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取送审单位的批次
            getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId)
                this.$axios.get("/school/get_constraint_by_institutionId", {
                        params: param
                    })
                    .then(res => {
                        console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //获取论文列表
            getPaperList(search, pageIndex, pageSize) {
                this.loading = true;
                // let param = new URLSearchParams();
                // param.append("institutionId", search.institutionId);
                // param.append("batchId", search.batchId);
                // param.append("statue", search.statue);
                // param.append("title", search.title);
                // param.append("oldId", search.oldId);
                // param.append("student", search.student);
                // param.append("pageIndex", pageIndex);
                // param.append("pageSize", pageSize);
                // this.$axios.get("/sending_paper/get_condition", {
                //     params: param
                // }).then(res => {
                //     console.log(res.data);
                //     this.paperList = res.data.data;
                //     this.page.total = res.data.count;
                //     this.loading = false;
                // }).catch(err => {
                //     this.$message.warning("服务器维护！");
                // });
            },
            //查看单篇论文的送审情况
            handleSingleCondition(paperId, batchId, paperName) {
                this.$router.push({
                    name: 'sendRecord'
                });
            },
            //发送评阅书
            sendEmailHandle(paperId) {
                //获取已选中的论文
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                if (selectRecords.length <= 0) {
                    this.$message.warning('请选择论文！');
                    return;
                }
                // this.process = 0;
                // this.sendProcessVis = true;
                const loading = this.$loading({
                    lock: true,
                    text: '正在发送',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let paperIds = [];
                selectRecords.forEach((item) => {
                    paperIds.push(item.paperId);
                });
                let param = {
                    paperIds: paperIds.join(',')
                };

                this.$axios.post("/sending_paper/send_email", param)
                    .then(res => {
                        // clearInteral(this.sendTimer);
                        loading.close();
                        this.showClose = true;
                        if (res.data.code == 1) {
                            this.process = 100;
                            this.sendProcessTitle = '发送成功';
                            this.$message.success("发送成功");
                        } else {
                            this.process = 99;
                            this.sendProcessTitle = '发送失败';
                            this.$message.warning("发送失败");
                        }
                    })
                    .catch(err => {
                        loading.close();
                        this.$message.warning("服务器维护！");
                    });
            },
            //获取发送精度
            getProcess() {
                this.$axios.get('sending_paper/progress')
                    .then(res => {
                        this.process = res.data.data;
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            batchSearch() {
                console.log(this.search);
                this.page.currentPage = 1;
                this.getOriginalPaperPreviews();
                this.collapseShow = !this.collapseShow;
            },
            resetHandle() {
                this.search = {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    oldId: '',
                    student: '',
                    statue: '',
                };
            },
            singleSearchPaper() {
                this.search['title'] = this.singleSearchValue;
                this.search['student'] = '',
                    this.search['batchId'] = '',
                    this.search['statue'] = '',
                    this.search['time'] = ['', ''],
                    this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取评阅书
            handleLookReview(paperId) {
                this.receiptDiaVis = true;
            },
            //查看评阅书
            handleLook(url) {
                window.open(url);
            },
            //下载评阅书
            handleDownloadReview() {

            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getOriginalPaperPreviews();
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getOriginalPaperPreviews();
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getOriginalPaperPreviews();
            },
            endPage() {
                this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
                    .pageSize == 0 ? 0 : 1);
                this.getOriginalPaperPreviews();
            },
            //获取选中的论文
            getSelectEvent() {
                let selectRecords = this.$refs.paperTable.getCheckboxRecords();
                console.log(selectRecords);
            },
            headerCellClassName({
                column,
                columnIndex
            }) {
                return 'vxe-table-thead'
            },
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            scroll(e) {
                console.log('scroll', e.target.scrollTop)
            },
            uploadPaperAnnexHandle(){
              this.uploadPaperAnnexModel = true;
            },
            closeAnnexHandle(){
              this.$refs.paperAnnex.uploadFiles.length = 0;
              this.annexFileList=[];
            },
            beforeAnnexUpload(file){
              console.log("beforeUO")
              const sizeLimit = file.size / 1024 / 1024 > 1024
              if (sizeLimit) {
                this.$message.warning('上传文件大小不能超过 1GB!')
              }
              const fileFamart = file.name.split('.')[file.name.split('.').length - 1];
              if (fileFamart !== 'zip') {
                this.$message.warning('必须上传zip格式的文件!')
              }
              return !sizeLimit && fileFamart === 'zip'
            },
            uploadAnnexSuccess(res, file, fileList) {
              console.log(res);
              this.showClose = true;
              if (res.code == '200') {
                // this.annexFileList.push({
                //   name:res.data.oldId,
                //   url: res.data.oldId,
                // });
                // console.log(this.annexFileList,fileList);
                this.$message.success("文件："+res.data.oldId+"，上传成功");
                // this.uploadAnnexTitle = '上传成功';
                // this.proStatus = 'success';
                // this.getOriginalPaperPreviews();
              } else {
                this.$message.warning("文件："+res.data.oldId+"，上传失败");
                console.log(file.name)
                fileList.forEach((item, idx) => {
                  if (file.name === item.name) {
                    fileList.splice(idx, 1);
                  }
                });
              }
              // this.annexFileList=[];
            },
            uploadAnnexError(err, file, fileList) {
              console.log(err)
              this.$message.warning("上传失败，服务器维护！");
              this.showClose = true;
              this.percent = 99;
              this.uploadAnnexTitle = '上传失败';
              this.proStatus = 'exception';
              this.annexFileList=[];
            },
            cfmUploadAnnexHandle() {
              // .paperInfo.uploadFiles.length
              //   console.log(this.$refs.paperAnnex)
              if (this.$refs.paperAnnex.uploadFiles.length < 1) {
                this.$message.warning("请选择附件信息");
                return;
              }
              // return;
              this.uploadAnnexData.batchId = this.batchId;
              this.$refs.paperAnnex.submit();
              // 附件上传

              this.showClose = false;

            },
          uploadAnnexProgress(){},
          handleAnnexChanged(file, fileList){
            let sum = 0
            fileList.forEach((item, idx) => {
              //在此处，对比文件名，将文件名相同的对比次数累加，
              // 相同的文件名累加值为 2 时，说明文件名已经重复，直接删掉。
              if (file.name === item.name) {
                sum++;
                if (sum === 2) {
                  this.$message({message: '文件名不能重复', type: 'info'});
                  fileList.splice(idx, 1);
                }
              }
            });
          },
          downloadAnnex(row){
            this.$message("附件下载");
            console.log(row);
            this.$api.paperLibrary.downloadFile({paperId:""+row.paperId} ).then(res => {
              //   const blob = new Blob([res.data]);
              // console.log(res)
              //   const fileName = row.annexUrl;
              //   if ('download' in document.createElement("a")) {
              //       const link = document.createElement("a")
              //       link.download = fileName
              //       link.style.display = 'none'
              //       link.href = URL.createObjectURL(res.data)
              //       document.body.appendChild(link)
              //       link.click()
              //       URL.revokeObjectURL(link.href)
              //       document.body.removeChild(link)
              //   } else {
              //       navigator.msSaveBlob(blob, fileName)
              //   }

              //   方法二
              console.log(res)
              console.log(typeof res.data)

              let blob = new Blob([res.data], {type: 'application/octet-stream;charset=utf-8'});
              console.log(blob,5)
              let link = document.createElement("a");
              let url = window.url || window.webkitURL;
              link.href = url.createObjectURL(blob);
              //重命名文件
              link.download = row.annexUrl;

              //释放内存
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              url.revokeObjectURL(link.href);
            }).catch(e => {
              this.$message("附件下载失败！");
            });

          },
        },
        destroyed() {
            clearInterval(this.timer);
        },
        //在页面离开时记录滚动位置
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
            next()
        },
        //进入该页面时，用之前保存的滚动位置赋值
        // beforeRouteEnter(to, from, next) {
        //     next(vm => {
        //         console.log(vm.scrollTop);
        //         console.log(vm.$refs)
        //     })
        // },
        components: {

        }
    }
</script>

<style scoped>
    .inputSelect {
        width: 120px;
    }

    .collapseInput {
        margin-top: 20px;
    }

    .searchBtn {
        padding: 20px 0px 0px 0px;
        text-align: center;
    }

    .box-card {
        padding-bottom: 0px !important;
    }

    .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }

    .form-line-item {
        width: 15em;
    }

    .el-form-item {
        margin-bottom: 10px !important;
    }

    
</style>
<style>
    .paperList .vxe-table--body-wrapper {
        height: calc(100vh - 410px);
    }

    .dialog .el-dialog {
        margin-top: 5vh !important;
        border-radius: 15px;
    }

    .searchForm .el-form-item__label {
        background-color: #F5F7FA;
        color: #909399;
    }
</style>